import { AssemblyComponent } from './assembly';
import { BuildInvoiceCyle } from './build.invoice_cycle';
import { BaseData } from './_global';
import { DRIVE_FOLDER_URL } from '@dis/gapi';

export interface Loadout {
  projectNumber: string;
  jobNumber: string;
  shippingContainer: string;
  outDate: Date;
  inDate: Date;
  status: string;
}

export interface Build extends BaseData {
  buildNumber: string;
  serialNumber: string;
  assemblyNumber: string;
  assemblyCard: string;
  description: string;
  folderId: string;
  toolType: string;
  buildComponents: BuildComponent[];
  components: string;
  status: string;
  list: string;
  projectNumber: string;
  jobNumber: string;
  projectNumber_jobNumber: string;
  loadout: Loadout;
  invoices: BuildInvoiceCyle[];
  dateOut: string;
  dateIn: string;
  notes: string;
  finalized: boolean;
  buildStatus: string;
  shipmentStatus: string;
  billingStatus: string;
  notesArray: any[];
  buildDate: string;
  functionTestCard: string;
  ranBoolean: boolean;
  rereleaseBoolean: boolean;
  approval: boolean;
}

export interface BuildComponent {
  assemblyComponent: AssemblyComponent;
  components: string | string[];
  multiBool: boolean;
}
export class Build extends BaseData {
  constructor(
    data: Partial<Build> = {},
  ) {
    super(data);
    this.uid = data.buildNumber || null;
    this.buildNumber = data.buildNumber || data.uid || null;
    this.assemblyNumber = data.assemblyNumber || null;
    this.assemblyCard = data.assemblyCard;
    this.description = data.description || null;
    this.serialNumber = data.serialNumber || null;
    this.folderId = data.folderId || data.buildFolderId || null;
    this.toolType = data.toolType || null;
    this.buildComponents = data.buildComponents || [];
    this.components = data.components || '';
    this.list = data.list || null;
    this.projectNumber = data.projectNumber || null;
    this.jobNumber = data.jobNumber || null;
    this.projectNumber_jobNumber = data.projectNumber_jobNumber || null;
    this.dateOut = data.dateOut || null;
    this.dateIn = data.dateIn || null;
    this.notes = data.notes || null;
    //@ts-expect-error
    this.finalized = data.finalized == "TRUE" ? true : false || false;
    this.loadout = data.loadout || {
      projectNumber: data.projectNumber || null,
      jobNumber: data.jobNumber || null,
      shippingContainer: null,
      outDate: null,
      inDate: null,
      status: null,
    };
    this.status = data.status || null;
    this.buildStatus = data.buildStatus || null;
    this.shipmentStatus = data.shipmentStatus || null;
    this.billingStatus = data.billingStatus || null;
    /**
    * @deprecated not sure if even being used;
    */
    this.invoices = [];
    this.notesArray = this.getBuildNotesArray(this.notes);
    this.buildDate = data.buildDate || null;
    this.functionTestCard = data.functionTestCard || null;

    this.ranBoolean = typeof data.ranBoolean === 'string' ?
      data.ranBoolean === "TRUE" ? true : false
      : typeof data.ranBoolean === 'boolean' ? data.ranBoolean : null || null;

    this.rereleaseBoolean = typeof data.rereleaseBoolean === 'string' ?
      data.rereleaseBoolean === "TRUE" ? true : false
      : typeof data.rereleaseBoolean === 'boolean' ? data.rereleaseBoolean : null || null;

    this.approval = typeof data.approval === 'string' ?
      data.approval === "TRUE" ? true : false
      : typeof data.approval === 'boolean' ? data.approval : null || null;
  }


  /**
   * Used for naming documents and folders, use this for consitenacy in naming
   */
  public get title() {
    return this.buildNumber;
  }

  /**
   * @deprecated use folderId instead;
   * Can probably be deleted
   */
  public get buildFolderId() {
    return this.folderId;
  }

  public get folderUrl() {
    return `${DRIVE_FOLDER_URL}${this.folderId}`;
  }

  private fromSpreadsheet(build: Partial<Build>) {
    this.buildDate = new Date(build.buildDate).toISOString();
  }

  private getBuildNotesArray(noteString: string) {
    if (noteString) {
      const noteArray = noteString.split('_');
      return noteArray;
    } else {
      const noteArray = [];
      return noteArray;
    }
  }
}

export const BUILD_ICON = {
  BUILD: 'build',
  ASSIGNED: 'assignment_turned_in',
  CREATED: 'add_circle',
  DOWNHOLE: 'oil_barrel',
  RECEIVED: 'call_received',
  SHIPPED: 'local_shipping'
}

export const BUILD_PIPLINE_STATUSES = {
  ASSIGNED: {
    icon: BUILD_ICON.ASSIGNED,
    iconColor: 'black',
    statusColor: 'gold'
  }
}

