import { TableQuery } from "./table.state";

const TITLE = 'Table';

export {
  Actions as TableStateActions
}

namespace Actions {
  export class UpdateState {
    public static readonly type = `[${TITLE}] UpdateState`;
    constructor(
      public tableName: string,
      public payload: Partial<TableQuery>
      ) { }
  }
}
