import { BaseData } from './_global';
import { MAPBOX_ACCESS_TOKEN_PK, MAPBOX_STATIC_URL } from './mapbox';


export interface Container extends BaseData {
  // Container's JTMS Properties:
  // General container properties for JTMS users are initally defined by Geoforce.
  // Select properties can be updated by users.
  // All Containers must require these properties.
  uid: string;
  description: string;
  containerStatus: string;
  trackable: boolean;
  tareWeight: number;
  // Geoforce and company basket properties
  id: string;
  type: ContainerType;
  length: number;
  width: number;
  height: number;
  vendor: string;
  status: string;
  coordinates: string;
  lon: number;
  lat: number;
  number: string;
  sizeType: string;
  timestamp: string;
}
export class Container extends BaseData {
  constructor(
    data: Partial<Container> = {},
  ) {
    super(data);
    this.uid = data.uid || null;
    this.description = data.description || null;
    this.trackable = data.trackable || null;
    this.containerStatus = data.containerStatus || null;
    this.tareWeight = data.tareWeight || null;

    this.id = data.id || null;
    this.type = data.type || null;
    this.length = data.length || null;
    this.width = data.width || null;
    this.height = data.height || null;
    this.vendor = data.vendor || null;
    this.status = data.status || null;
    this.coordinates = data.coordinates || null;
    this.lon = data.lon || null;
    this.lat = data.lat || null;
    this.number = data.number || null;
    this.sizeType = data.sizeType || null;
    this.timestamp = data.timestamp || null;
  }


  // TODO: Do this better is hacked
  public get staticMapboxImgUrl_desktop() {
    const [lon, lat] = [this.lon?.toString().slice(0, 8), this.lat?.toString().slice(0, 7)];
    const zoom = lon.slice(0, 6) === "-92.04" && lat.slice(0, 5) === "30.27" ? "9" : "4";
    const pin = `pin-s-c(${lon},${lat})`;
    return MAPBOX_STATIC_URL
      .replace("{pin}", pin)
      .replace("{lon}", lon)
      .replace("{lat}", lat)
      .replace("{zoom}", zoom)
      .replace("{width}", "300")
      .replace("{height}", "100")
      .replace("{accessToken}", MAPBOX_ACCESS_TOKEN_PK);
  }

  // TODO: Do this better is hacked
  public get staticMapboxImgUrl_handset() {
    const [lon, lat] = [this.lon?.toString().slice(0, 8), this.lat?.toString().slice(0, 7)];
    const zoom = lon.slice(0, 6) === "-92.04" && lat.slice(0, 5) === "30.27" ? "9" : "4";
    const pin = `pin-s-c(${lon},${lat})`;
    return MAPBOX_STATIC_URL
      .replace("{pin}", pin)
      .replace("{lon}", lon)
      .replace("{lat}", lat)
      .replace("{zoom}", zoom)
      .replace("{width}", "100")
      .replace("{height}", "75")
      .replace("{accessToken}", MAPBOX_ACCESS_TOKEN_PK);
  }
}

export enum ContainerType {
  BASKET = "basket",
  PALLET = "pallet",
  PICKUP = "pickup",
  CRATE = "crate",
}
