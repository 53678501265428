import { AfterContentInit, Component, ContentChild, ContentChildren, Input, QueryList, TrackByFunction, ViewChild } from '@angular/core';
import { MatHeaderRowDef, MatRowDef, MatColumnDef, MatNoDataRow, MatTable, MatTableModule, MatFooterRowDef } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

import { TableDataSource } from '../tables.datasource';
import { TableFilterQrComponent } from '../table-filter-qr/table-filter-qr.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'lib-collections-wrapper-table',
  templateUrl: './collections-wrapper-table.component.html',
  styleUrls: [
    './collections-wrapper-table.component.css',
    // '../styles/jtms-tables.global.css',
    // '../styles/status.global.css',
  ],
  standalone: true,
  imports: [
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    TableFilterQrComponent
  ]
})
export class CollectionsWrapperTable<T> implements AfterContentInit {
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ContentChildren(MatFooterRowDef) footerRowDefs: QueryList<MatFooterRowDef>;
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChild(MatNoDataRow) noDataRow: MatNoDataRow;

  @ViewChild(MatTable, { static: true }) table: MatTable<T>;

  @Input({required: true}) columns: string[];

  @Input({required: true}) dataSource: TableDataSource<T>;

  @Input({required: true}) tableName: string;

  @Input({required: true}) trackByUid: TrackByFunction<T>;

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.footerRowDefs.forEach(footerRowDef => this.table.addFooterRowDef(footerRowDef));
    this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this.table.setNoDataRow(this.noDataRow);
  }
}
