import { Component, Input } from '@angular/core';

import { Store } from '@ngxs/store';

import { Part } from '@dis/models';
import { PartsState } from '../../jtms';

@Component({
  selector: 'part-image',
  templateUrl: './part-image.component.html',
  styleUrls: ['./part-image.component.css'],
  standalone: true
})
export class PartImageComponent {

  @Input({ required: true }) partNumber: string;
  @Input() rotateImage: boolean;
  @Input() showConnections: boolean;

  imageUrl: string;
  part: Part;

  constructor(
    private store: Store,
  ) {
    this.store.selectSnapshot(PartsState.getById(this.partNumber))
  }

}
