import { Injectable } from "@angular/core";

import { tap } from "rxjs";

import { NgxsAfterBootstrap, Action, Selector, State, StateContext, StateToken, createSelector } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { upsertItem } from "../../operators/ngxs-upsertItem";

import { BaseCollectionsState } from "../../../states/base.state";

import { TableQuery, TableState } from "../../../components/tables";


/**
 *  ~~~~ ONLY EDIT BETWEEN THESE COMMENTS ~~~~
 */

import { COLLECTIONS, GeoJson, Container as Model } from "@dis/models";
import { ContainersStateActions as StateActions } from "./actions";
import { ContainersFirestore } from "../service/containers.firestore";

const COLLECTION = COLLECTIONS.CONTAINERS;

export {
  StateModel as ContainersStateModel,
  STATE_TOKEN as CONTAINERS_STATE_TOKEN,
  StateStore as ContainersState
};

/**
 * ^^^^ ONLY EDIT BETWEEN THESE COMMENTS ^^^^
 */

const STATE_TOKEN = new StateToken<StateModel>(`${COLLECTION.toLowerCase()}State`);

interface StateModel {
  loading: boolean;
  items: Model[];
  filterText: string;
}

@State({
  name: STATE_TOKEN,
  defaults: {
    loading: false,
    items: [],
    filterText: null,
  }
})
@Injectable()
class StateStore extends BaseCollectionsState implements NgxsAfterBootstrap {
  /**
   *  DO NOT ADD SELCTORS HERE USE THE "stateName.selectors.ts file"
   */

  @Selector([STATE_TOKEN])
  static items(state: StateModel) {
    return state.items;
  }

  @Selector([StateStore.items])
  static count(items: Model[]) {
    return items.length;
  }

  @Selector([StateStore.items])
  static mapGeoJson(items: Model[]) {
    return items.filter(items => items.trackable)
      .map(item => {
        const { lon, lat } = item;
        return new GeoJson([lon, lat], item);
      });
  }

  @Selector([StateStore.items, TableState.tableQuery(COLLECTION)])
  static tableItems(items: Model[], { active, direction }: TableQuery) {
    return this.sortByActive(items, active, direction);
  }

  @Selector([StateStore.tableItems, TableState.tableQuery(COLLECTION)])
  static filtered(items: Model[], { filterText }: TableQuery) {
    return filterText
      ? [...items.filter(item => this.filterPredicate(item, filterText))]
      : [...items]
  }

  static getById(uid: string) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => item.uid === uid)[0];
    })
  }

  /**
   * @deprecated moving to getByStatuses so can provide more than one status
   * @param  {string} status
   */
  static getByStatus(status: string) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => item.status === status);
    })
  }

  static getByStatuses(statuses: string[]) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => statuses.includes(item.status));
    })
  }

  constructor(
    private db: ContainersFirestore,
  ) {
    super();
  }

  ngxsAfterBootstrap({ dispatch, patchState }: StateContext<any>): void {
    patchState({ loading: true });
    dispatch(new StateActions.GetAll);
  }

  @Action(StateActions.FilterList, { cancelUncompleted: true })
  filterList({ patchState }: StateContext<StateModel>, { payload: filterText }: StateActions.FilterList) {
    patchState({ filterText })
  }

  @Action(StateActions.GetAll, { cancelUncompleted: true })
  getAll({ patchState, dispatch }: StateContext<StateModel>) {
    patchState({ loading: true });
    return this.db.collection$()
      .pipe(
        tap(items => patchState({
          loading: false,
          items
        })),
      );
  }

  // @Action(StateActions.StartFirestoreCollectionWatcher)
  // async watchCollection({ dispatch, patchState }: StateContext<StateModel>) {
  //   patchState({ loading: true });
  //   return this.db.collection$().pipe(
  //     mergeMap(items => dispatch(new StateActions.UpdateFromFirestoreWatcher(items)))
  //   )
  // }

  @Action(StateActions.UpdateFromFirestoreWatcher)
  updateState({ patchState, setState }: StateContext<StateModel>, { payload: items }: StateActions.UpdateFromFirestoreWatcher) {
    items.map(_item => {
      setState(
        patch<StateModel>({
          items: upsertItem<Model>(item => item.uid === _item.uid, _item),
        })
      )
    }
    )
    patchState({ loading: false })
  }

  /**
   *
   *
   * Below are actions that dispatch CRUD operations to the db handling the collection
   *
   */

  @Action(StateActions.Update)
  async update({ patchState }: StateContext<StateModel>, { payload }: StateActions.Update) {
    patchState({ loading: true });

    const item = payload;
    console.log({ item })
    // item.folderId = await this.db.checkFolder(item);
    await this.db.upsert(item.uid, item);

    patchState({ loading: false });
  }

  @Action(StateActions.Delete)
  async delete({ patchState }: StateContext<StateModel>, { payload }: StateActions.Delete) {
    patchState({ loading: true });

    const item = payload;
    // await this.db.deleteFile(item.folderId);
    // await this.db.delete(item.uid);

    patchState({ loading: false })
  }

}

