<div>
  <div>
    @if(showConnections) {
    <small>{{part?.topConnection}}</small>
    }
  </div>
  <div class="image-wrapper">
    <img [class.rotate]="rotateImage" src="cdn/images/tools/{{partNumber}}.png"
      onerror="javascript:this.src='assets/icons/icon-128x128.png'">
    <div class="overlay" [class.rotate]="rotateImage">
    </div>
  </div>
  <div>
    @if(showConnections) {
    <small>{{part?.bottomConnection}}</small>
    }
  </div>
</div>
