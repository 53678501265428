<table mat-table [dataSource]="dataSource" [trackBy]="trackByUid">

  <ng-container matColumnDef="table-header">
    <th mat-header-cell *matHeaderCellDef [colSpan]="columns.length">
      <lib-table-filter-qr [tableName]="tableName"></lib-table-filter-qr>
    </th>
  </ng-container>

  <ng-content></ng-content>

  <ng-container matColumnDef="footer">
    <td mat-footer-cell *matFooterCellDef [colSpan]="columns.length">
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 250, 300, 500]">
      </mat-paginator>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['table-header']; sticky: true"></tr>
  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>

  <tr mat-footer-row *matFooterRowDef="['footer']; sticky: true"></tr>

  <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell">
      <h3>
        No results available....
      </h3>
      Try adjusting your search criteria.
    </td>
  </tr>

</table>
