import { Container as Model } from "@dis/models";
const title = 'CONTAINERS';
export { StateActions as ContainersStateActions }

namespace StateActions {
  export class Delete {
    public static readonly type = `[${title}] Delete`;
    constructor(public payload: Model) { }
  }
  export class GetAll {
    public static readonly type = `[${title}] Get all`;
  }

  export class GetQuery {
    public static readonly type = `[${title}] Get query`;
  }

  export class FilterList {
    public static readonly type = `[${title}] Filter list`;
    constructor(public payload: string) { }
  }

  export class StartFirestoreCollectionWatcher {
    public static readonly type = `[${title}] Start Firestore Collection Watcher`;
  }

  export class UpdateFromFirestoreWatcher {
    public static readonly type = `[${title}] Update from Firestore Watcher`;
    constructor(public payload: Model[]) { }
  }

  export class Update {
    public static readonly type = `[${title}] Update`;
    constructor(public payload: Model) { }
  }
}
