import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken, createSelector } from "@ngxs/store";
import { TableStateActions as StateActions } from "./table.actions";
import { Sort } from "@angular/material/sort";
import { COLLECTIONS } from "@dis/models";

export interface TableQuery {
  filterText?: string;
  sort?: Sort,
  active?: string;
  direction?: 'asc' | 'desc';
}

interface StateModel {
  [tableName: string]: TableQuery;
}

const STATE_NAME = 'table';
const STATE_TOKEN = new StateToken<StateModel>(`${STATE_NAME}State`);

export {
  StateModel as TableStateModel,
  STATE_TOKEN as TABLE_STATE_TOKEN
}

@State({
  name: STATE_TOKEN,
  defaults: {
    [COLLECTIONS.QUOTES]: {
      filterText: null,
      active: null,
      direction: null
    },
    [COLLECTIONS.PROJECTS]: {
      filterText: null,
      active: null,
      direction: null
    },
    [COLLECTIONS.JOBS]: {
      filterText: null,
      active: null,
      direction: null
    },
    [COLLECTIONS.BUILDS]: {
      filterText: null,
      active: null,
      direction: null
    },
    [COLLECTIONS.ASSEMBLIES]: {
      filterText: null,
      active: null,
      direction: null
    },
    [COLLECTIONS.COMPONENTS]: {
      filterText: null,
      active: null,
      direction: null
    },
    [COLLECTIONS.PARTS]: {
      filterText: null,
      active: null,
      direction: null
    },
    [COLLECTIONS.CONTAINERS]: {
      filterText: null,
      active: null,
      direction: null
    },
    [COLLECTIONS.INVOICES]: {
      filterText: null,
      active: null,
      direction: null
    },
    [COLLECTIONS.EQUIPMENT]: {
      filterText: null,
      active: null,
      direction: null
    },
  }
})
@Injectable()
export class TableState {

  static tableQuery(table: string) {
    return createSelector([STATE_TOKEN], (state: StateModel) => {
      return state[table];
    })
  }

  @Action(StateActions.UpdateState)
  updateTableQuery({ patchState, getState }: StateContext<StateModel>, { tableName, payload }: StateActions.UpdateState) {
    const state = getState();
    patchState({
      [tableName]: {
        ...state[tableName],
        ...payload,
      }
    })
  }
}
