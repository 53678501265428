import { Injectable } from "@angular/core";

import { map, mergeMap, skipWhile, tap } from "rxjs";

import { NgxsAfterBootstrap, Action, Selector, State, StateContext, StateToken, createSelector, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { upsertItem } from "../../operators/ngxs-upsertItem";

import { AuthState } from "@dis/auth";
import { BaseCollectionsState } from "../../../states/base.state";

import { TableQuery, TableState } from "../../../components/tables";

/**
 *  ~~~~ ONLY EDIT BETWEEN THESE COMMENTS ~~~~
 */

import { COLLECTIONS, Build as Model } from "@dis/models";
import { BuildsSheetStore as SheetStore } from "../services/sheetstore";
import { BuildsStateActions as StateActions } from "./actions";

const COLLECTION = COLLECTIONS.BUILDS;

export {
  StateModel as BuildsStateModel,
  STATE_TOKEN as BUILDS_STATE_TOKEN,
  StateStore as BuildsState,
};

/**
 * ^^^^ ONLY EDIT BETWEEN THESE COMMENTS ^^^^
 */

const STATE_TOKEN = new StateToken<StateModel>(`${COLLECTION.toLowerCase()}sState`);

interface StateModel {
  loading: boolean;
  items: Model[];
  filterText: string;
}

@State({
  name: STATE_TOKEN,
  defaults: {
    loading: false,
    items: [],
    filterText: null,
  }
})
@Injectable()
class StateStore extends BaseCollectionsState implements NgxsAfterBootstrap {
  /**
   *  DO NOT ADD SELCTORS HERE USE THE "stateName.selectors.ts file"
   */

  @Selector([STATE_TOKEN])
  static items(state: StateModel) {
    return state.items;
  }

  @Selector([StateStore.items])
  static count(items: Model[]) {
    return items.length;
  }

  @Selector([StateStore.items, TableState.tableQuery(COLLECTION)])
  static tableItems(items: Model[], { active, direction }: TableQuery) {
    return this.sortByActive(items, active, direction);
  }

  @Selector([StateStore.tableItems, TableState.tableQuery(COLLECTION)])
  static filtered(items: Model[], { filterText }: TableQuery) {
    return filterText
      ? [...items.filter(item => this.filterPredicate(item, filterText))]
      : [...items]
  }

  static getById(uid: string) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => item.uid === uid)[0];
    })
  }

  /**
   * @deprecated moving to getByStatuses so can provide more than one status
   * @param  {string} status
   */
  static getByStatus(status: string) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => item.status === status);
    })
  }

  static getByStatuses(statuses: string[]) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => statuses.includes(item.status));
    })
  }

  static getByJob(jobNumber: string) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => item.jobNumber === jobNumber);
    })

  }

  constructor(
    private db: SheetStore,
    private store: Store,
  ) {
    super();
  }

  ngxsAfterBootstrap({ dispatch, patchState }: StateContext<any>): void {
    patchState({ loading: true });
    this.store.select(AuthState.hasGapiToken)
      .pipe(
        skipWhile(value => !value),
        tap(() => dispatch(new StateActions.GetAll()))
      ).subscribe()
  }

  // @Action(StateActions.FilterList, { cancelUncompleted: true })
  // filterList({ patchState }: StateContext<StateModel>, { payload: filterText }: StateActions.FilterList) {
  //   patchState({ filterText })
  // }

  @Action(StateActions.GetAll, { cancelUncompleted: true })
  getAll({ dispatch, patchState }: StateContext<StateModel>) {
    patchState({ loading: true })
    return this.db.collection$().pipe(
      map(items => items.map(item => new Model(item))),
      tap(items => patchState({
        loading: false,
        items
      })),
      mergeMap(() => dispatch(new StateActions.StartCollectionSheetWatcher))
    )
  }

  @Action(StateActions.StartCollectionSheetWatcher)
  async watchCollection({ dispatch, patchState }: StateContext<StateModel>) {
    patchState({ loading: true });
    return this.db.docs$().pipe(
      mergeMap(items => dispatch(new StateActions.UpdateFromSheetWatcher(items)))
    )
  }

  @Action(StateActions.UpdateFromSheetWatcher)
  updateState({ patchState, setState }: StateContext<StateModel>, { payload: items }: StateActions.UpdateFromSheetWatcher) {
    items.map(_item => {
      _item = new Model(_item);
      setState(
        patch<StateModel>({
          items: upsertItem<Model>(item => item.uid === _item.uid, _item),
        })
      )
    }
    )
    patchState({ loading: false })
  }

  /**
   *
   *
   * Below are actions that dispatch CRUD operations to the db handling the collection
   *
   */

  @Action(StateActions.Update)
  async update({ patchState }: StateContext<StateModel>, { payload }: StateActions.Update) {
    patchState({ loading: true });

    const item = payload;
    await this.db.upsertWithSheetsApi(item.uid, item);

    patchState({ loading: false });
  }

  @Action(StateActions.ToSheetsApi.BatchUpdate)
  async batchUpdate({ patchState }: StateContext<StateModel>, { payload }: StateActions.ToSheetsApi.BatchUpdate) {
    patchState({ loading: true });

    const items = payload;
    this.db.batchUpdate(payload)

    patchState({ loading: false });
  }

  @Action(StateActions.Delete)
  async delete({ patchState }: StateContext<StateModel>, { payload }: StateActions.Delete) {
    patchState({ loading: true });

    const item = payload;
    await this.db.deleteFile(item.folderId);
    await this.db.delete(item.uid);

    patchState({ loading: false })
  }

}

